import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getJiraProjectRooms,
  getJiraProjects,
  manageJiraRooms,
} from "../../api/jira";
import { Button, List, Typography, message } from "antd";
import { AddRoomModel } from "../../components/addRoomModel";
import { ShowRoomModel } from "../../components/showRoomModel/showRoomModel";

const { Title } = Typography;

export function JiraProject() {
  const { jiraID } = useParams();
  const [jiraProjects, setJiraProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projToAddRoom, setProjToAddRoom] = useState(null);
  const [projToShowRoom, setProjToShowRoom] = useState(null);

  const fetchJiraProjects = async () => {
    try {
      setIsLoading(true);
      const { projects } = await getJiraProjects(jiraID);
      setJiraProjects(projects);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      message.error("Error getting the project");
    }
  };

  const handleFetchRooms = async ({ page, perPage }, project) => {
    const { totalCount, projectRooms } = await getJiraProjectRooms({
      page,
      perPage,
      projectID: project.projectID,
    });

    if (!totalCount) return { totalCount, rooms: [] };

    return { totalCount, rooms: projectRooms.map((pr) => pr.room) };
  };

  const handleRoomUpdate = ({ add, remove }, project) => {
    return manageJiraRooms({ add, remove, projectID: project.projectID });
  };

  useEffect(() => {
    if (!jiraID) return;
    fetchJiraProjects();
  }, [jiraID]);

  return (
    <div>
      <Title level={5}>List of available projects</Title>

      <List
        itemLayout="horizontal"
        dataSource={jiraProjects}
        loading={isLoading}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="link" onClick={() => setProjToAddRoom(item)}>
                Add Rooms
              </Button>,
              <Button type="link" onClick={() => setProjToShowRoom(item)}>
                Show Rooms
              </Button>,
              <Button type="link" href={item.url}>
                Go to jira
              </Button>
            ]}
          >
            <List.Item.Meta
              title={<a href={item.url}>{item.name}</a>}
              // description={item.repository.description}
            />
          </List.Item>
        )}
      />

      {projToAddRoom ? (
        <AddRoomModel
          onClose={() => setProjToAddRoom(null)}
          open={!!projToAddRoom}
          title={`Add project to ${projToAddRoom.name}`}
          onConfirm={async (data) => {
            await handleRoomUpdate(data, projToAddRoom);
            setProjToAddRoom(null);
          }}
        />
      ) : null}

      {projToShowRoom ? (
        <ShowRoomModel
          onClose={() => setProjToShowRoom(null)}
          open={!!projToShowRoom}
          title={`Linked rooms to project ${projToShowRoom.name}`}
          fetchRooms={async (pageData) =>
            handleFetchRooms(pageData, projToShowRoom)
          }
          onConfirm={async (data) => {
            await handleRoomUpdate(data, projToShowRoom);
            setProjToShowRoom(null);
          }}
        />
      ) : null}
    </div>
  );
}
