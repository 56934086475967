import { Button, List, Modal, Select, Spin, Typography } from "antd";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { getRooms } from "../../api/rooms";

export function AddRoomModel({ title, close, onConfirm, onClose }) {
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedRooms, setSelectedRooms] = useState([]);
  const [searchRooms, setSearchRooms] = useState([]);

  const debouncedSearch = useDebouncedCallback(async (value) => {
    setIsSearching(true);
    const { rooms } = await getRooms({ q: value });
    setSearchRooms(rooms);
    setIsSearching(false);
  }, 500);

  const onRemoveItem = (item) => {
    setSelectedRooms(selectedRooms.filter((sr) => sr.uid !== item.uid));
  };

  const onAddItem = (item) => {
    setSelectedRooms([...selectedRooms, item]);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onConfirm({ add: selectedRooms, remove: [] });
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  if (close) return null;

  return (
    <Modal
      title={
        <Typography.Title level={3} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      }
      open={!close}
      okText="Comfirm"
      okButtonProps={{ size: "large", loading: isSubmitting }}
      cancelButtonProps={{ size: "large" }}
      confirmLoading={isSubmitting}
      onOk={async () => {
        await handleSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      <Select
        showSearch
        size="large"
        placeholder="Search Rooms"
        loading={isSearching}
        prefix={<SearchOutlined />}
        value={null}
        showArrow={false}
        onSearch={(e) => debouncedSearch(e)}
        notFoundContent={isSearching ? <Spin size="small" /> : null}
        optionLabelProp="label"
        labelInValue
        filterOption={false}
        style={{ margin: "16px 0 0 0", width: "100%" }}
      >
        {searchRooms.map((room) => {
          return (
            <Select.Option
              value={room.uid}
              label={room.name}
              title={room.description}
            >
              <List.Item.Meta
                title={room.name}
                onClick={() => onAddItem(room)}
              />
            </Select.Option>
          );
        })}
      </Select>

      {!!searchRooms.length && (
        <List
          itemLayout="horizontal"
          dataSource={selectedRooms}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  onClick={() => onRemoveItem(item)}
                  danger
                ></Button>,
              ]}
            >
              <List.Item.Meta title={item.name} />
            </List.Item>
          )}
        />
      )}
    </Modal>
  );
}
