import { BASE_URL } from "../configs";

export async function getRooms({ q }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `${BASE_URL}/api/github/rooms/search?`;
  if (q) {
    url += `&q=${q}`;
  }

  const response = await fetch(url, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function manageAppRooms({ add, remove, appID }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `${BASE_URL}/api/github/repo/manage`;

  const response = await fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify({ add, remove, appID }),
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function fetchRepoRooms({ appID, page, perPage }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `${BASE_URL}/api/github/app/${appID}/rooms?`;

  if (page) {
    url += `page=${page}&`;
  }

  if (perPage) {
    url += `perPage=${perPage}&`;
  }

  const response = await fetch(url, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}
