import { Avatar, List, Skeleton, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getInstallation, getRepositories } from "../../../api/github";
import { GithubOutlined } from "@ant-design/icons";

export function GithubInstall() {
  const { installationID } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!installationID) return;

    const fetchInstallation = async () => {
      try {
        setIsLoading(true);
        await getRepositories({ page: 3, installationID });
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    };

    fetchInstallation();
  }, [installationID]);

  if (isLoading || !installationID) {
    return <Spin />;
  }

  return "install app " + installationID;
}
