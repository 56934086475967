import { Button, List, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

export function ShowRoomModel({
  title,
  fetchRooms,
  onConfirm,
  close,
  onClose,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedRooms, setSelectedRooms] = useState([]);
  const [deletedRooms, setDeletedRooms] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const paginationProps = {
    current: page,
    total: totalCount,
    pageSize: perPage,
    align: "start",
    onChange: setPage,
  };

  const fetchRoomData = async () => {
    try {
      setIsLoading(true);
      const { totalCount, rooms } = await fetchRooms({
        page,
        perPage,
      });
      setTotalCount(totalCount);
      setSelectedRooms(rooms);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onUndoRemoveItem = (room) => {
    setDeletedRooms(deletedRooms.filter((dr) => dr.uid !== room.uid));
  };

  const onRemoveItem = (room) => {
    setDeletedRooms([...deletedRooms, room]);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await onConfirm({ add: [], remove: deletedRooms });
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (close) return;
    fetchRoomData();
  }, [page, perPage]);

  if (close) return null;

  return (
    <Modal
      title={
        <Typography.Title level={3} style={{ margin: 0 }}>
          {title}
        </Typography.Title>
      }
      open={!close}
      okText="Confirm"
      okButtonProps={{ size: "large", loading: false }}
      cancelButtonProps={{ size: "large" }}
      confirmLoading={isSubmitting}
      onOk={async () => {
        await handleSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      <List
        itemLayout="horizontal"
        loading={isLoading}
        pagination={totalCount ? paginationProps : null}
        dataSource={selectedRooms}
        renderItem={(room) => {
          const actionButtons = [];
          if (deletedRooms.some((dr) => dr.uid === room.uid)) {
            actionButtons.push(
              <Button
                icon={<DeleteOutlined />}
                onClick={() => onUndoRemoveItem(room)}
                danger
              >
                Undo Delete
              </Button>
            );
          } else {
            actionButtons.push(
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                onClick={() => onRemoveItem(room)}
                danger
              ></Button>
            );
          }

          return (
            <List.Item actions={actionButtons} u>
              <List.Item.Meta title={room.name} />
            </List.Item>
          );
        }}
      />
    </Modal>
  );
}
