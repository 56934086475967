import { Button, List, Modal, Select, Skeleton, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  SearchOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { fetchRepoRooms, getRooms, manageAppRooms } from "../../api/rooms";

export function ShowRepoModel({ app, onConfirm, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedRooms, setSelectedRooms] = useState([]);
  const [deletedRooms, setDeletedRooms] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const paginationProps = {
    current: page,
    total: totalCount,
    pageSize: perPage,
    align: "start",
    onChange: setPage,
  };

  const fetchRepo = async () => {
    try {
      setIsLoading(true);
      const appRooms = await fetchRepoRooms({
        appID: app.uid,
        page,
        perPage,
      });
      setTotalCount(appRooms.totalCount);
      setSelectedRooms(appRooms.appRooms);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onUndoRemoveItem = (item) => {
    setDeletedRooms(deletedRooms.filter((dr) => dr.uid !== item.room.uid));
  };

  const onRemoveItem = (item) => {
    setDeletedRooms([...deletedRooms, item.room]);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await manageAppRooms({ add: [], remove: deletedRooms, appID: app.uid });
      setIsSubmitting(false);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (!app) return;
    fetchRepo();
  }, [app, page, perPage]);

  if (!app) return null;

  return (
    <Modal
      title={
        <Typography.Title level={3} style={{ margin: 0 }}>
          {`${app.repository.name} Linked Rooms`}
        </Typography.Title>
      }
      open={!!app}
      okText="Confirm"
      okButtonProps={{ size: "large", loading: false }}
      cancelButtonProps={{ size: "large" }}
      confirmLoading={isSubmitting}
      onOk={async () => {
        await handleSubmit();
        onConfirm();
      }}
      onCancel={() => {
        console.log("cancelled");
        onClose();
      }}
    >
      <List
        itemLayout="horizontal"
        loading={isLoading}
        pagination={totalCount ? paginationProps : null}
        dataSource={selectedRooms}
        renderItem={(item) => {
          const actionButtons = [];
          if (deletedRooms.some((dr) => dr.uid === item.room.uid)) {
            actionButtons.push(
              <Button
                icon={<DeleteOutlined />}
                onClick={() => onUndoRemoveItem(item)}
                danger
              >
                Undo Delete
              </Button>
            );
          } else {
            actionButtons.push(
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                onClick={() => onRemoveItem(item)}
                danger
              ></Button>
            );
          }

          return (
            <List.Item actions={actionButtons} u>
              <List.Item.Meta title={item.room.name} />
            </List.Item>
          );
        }}
      />
    </Modal>
  );
}
