import { BASE_URL } from "../configs";

export async function getRepositories({ page, perPage, installationID }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `${BASE_URL}/api/github/repo?`;
  if (page) {
    url += `&page=${page}`;
  }

  if (installationID) {
    url += `&installationID=${installationID}`;
  }

  if(perPage) {
    url += `&perPage=${perPage}`;
  }

  const response = await fetch(url, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}
