import React, { useMemo, useState } from "react";
import { Avatar, Dropdown, Layout, Menu, Space, theme, Typography } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Icon, {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  GithubOutlined,
} from "@ant-design/icons";

import "./dashboard.css";
import { logout } from "../../helpers/auth/authApi";
import { ReactComponent as JiraIc } from "../../ui-kit/icons/jira.svg";

const { Header, Sider, Content } = Layout;
const { Text, Title } = Typography;

export function Dashboard({ user, org }) {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const sidebarItems = useMemo(() => {
    return [
      {
        key: "/",
        icon: <HomeOutlined />,
        label: "Home",
        onClick: () => navigate("/"),
      },
      {
        key: "/github-integration",
        icon: <GithubOutlined />,
        label: "Github Integration",
        onClick: () => navigate("/github-integration"),
      },
      {
        key: "/jira-integration",
        icon: <Icon component={JiraIc} />,
        label: "Jira Integration",
        onClick: () => navigate("/jira-integration"),
      },
    ];
  }, [navigate]);

  const selectedMenu = useMemo(() => {
    if (!sidebarItems.length) return {};
    return sidebarItems.find((si) => si.key === pathname);
  }, [sidebarItems, pathname]);

  return (
    <Layout className="Dashboard">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ width: "295px" }}
      >
        <Space className="title-section">
          <img className="logo" src={`${org.pic}/l`} />
          <Title level={5} className="title">
            {org.fullOrgName}
          </Title>
        </Space>

        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[pathname]}
          items={sidebarItems}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="header"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          {selectedMenu && (
            <Space>
              <Title level={5}>{selectedMenu.label}</Title>
            </Space>
          )}

          <Dropdown
            menu={{
              items: [
                {
                  key: "logout",
                  label: "logout",
                  onClick: async () => {
                    await logout();
                  },
                },
              ],
            }}
            placement="topLeft"
            trigger="click"
            arrow
          >
            <Space className="user-section">
              <Avatar src={`${user.pic}/l`}>{user.name[0]}</Avatar>
              <Text>{user.name}</Text>
            </Space>
          </Dropdown>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}
