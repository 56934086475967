import { BASE_URL } from "../../configs";

export async function getSessionDetails() {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`${BASE_URL}/api/getSessionDetails`, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function getLoginRedirectUrl() {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(
    `${BASE_URL}/api/authRedirectUrl?url=${window.location.href}`,
    {
      method: "get",
      headers,
    }
  );

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function logout() {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  const response = await fetch(`${BASE_URL}/api/logout`, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}
