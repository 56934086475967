import { BASE_URL } from "../configs";

export async function getJiraAuthUrl() {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let scopes = `manage:jira-webhook read:jira-work offline_access`;

  let url = `${BASE_URL}/api/jira/authRedirectUrl?audience=api.atlassian.com&response_type=code&prompt=consent&scopes=${scopes}`;

  const response = await fetch(url, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function getJiraApps({ perPage, page }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `${BASE_URL}/api/jira/app?`;

  if (page) {
    url += `page=${page}&`;
  }

  if (perPage) {
    url += `perPage=${perPage}&`;
  }
  const response = await fetch(url, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function getJiraProjects(jiraID) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `${BASE_URL}/api/jira/app/${jiraID}`;

  const response = await fetch(url, {
    method: "get",
    headers,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function manageJiraRooms({ add, remove, projectID }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `${BASE_URL}/api/jira/project/${projectID}/manage`;

  const response = await fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify({ add, remove }),
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}

export async function getJiraProjectRooms({ page, perPage, projectID }) {
  var headers = new Headers();
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let url = `${BASE_URL}/api/jira/project/${projectID}/room?`;

  if (page) {
    url += `page=${page}&`;
  }

  if (perPage) {
    url += `perPage=${perPage}&`;
  }

  const response = await fetch(url, {
    method: "get",
    headers,
    body: null,
    credentials: "include",
  });

  if (response.status > 400) {
    throw response;
  }

  const res = await response.json();
  return res.Data;
}
