import {
  Button,
  List,
  Typography,
} from "antd";
import { GithubOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../configs";
import { getRepositories } from "../../api/github";
import { useEffect, useState } from "react";
import { AddRepoModel } from "./addRepoModal";
import { ShowRepoModel } from "./showRepoModal";

const { Title } = Typography;

export function GithubIntegration({ org }) {
  const [isLoading, setIsLoading] = useState(false);
  const [repos, setRepos] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(2);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRepoToAdd, setSelectedRepoToAdd] = useState(null);
  const [showRepo, setShowRepo] = useState(null);

  const fetchRepo = async () => {
    try {
      setIsLoading(true);
      const repos = await getRepositories({ page, perPage });
      setTotalCount(repos.totalCount);
      setRepos(repos.installation);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    fetchRepo();
  }, [page]);

  const paginationProps = {
    current: page,
    total: totalCount,
    pageSize: perPage,
    onChange: setPage,
  };

  return (
    <div className="Github">
      <Button
        icon={<GithubOutlined />}
        href={`${BASE_URL}/api/github/install?org=${org.name}`}
      >
        Add New Repository
      </Button>

      <Title level={5}>List of installed repositories</Title>

      <List
        itemLayout="horizontal"
        dataSource={repos}
        pagination={totalCount ? paginationProps : null}
        loading={isLoading}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="link" onClick={() => setSelectedRepoToAdd(item)}>
                Add Rooms
              </Button>,
              <Button type="link" onClick={() => setShowRepo(item)}>
                Show Rooms
              </Button>,
              <Button type="link" href={item.repository.url}>
                Go to github
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={<a href={item.repository.url}>{item.repository.name}</a>}
              description={item.repository.description}
            />
          </List.Item>
        )}
      />

      {selectedRepoToAdd && (
        <AddRepoModel
          app={selectedRepoToAdd}
          onConfirm={() => setSelectedRepoToAdd(null)}
          onClose={() => setSelectedRepoToAdd(null)}
        />
      )}

      {showRepo && (
        <ShowRepoModel
          app={showRepo}
          onConfirm={() => setShowRepo(null)}
          onClose={() => setShowRepo(null)}
        />
      )}
    </div>
  );
}
