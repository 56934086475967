import { Routes, Route } from "react-router-dom";
import { Dashboard } from "./features/dashboard";
import { ConfigProvider } from "antd";

import "./App.css";
import { redirectLogin, useSession } from "./helpers/auth/useSession";
import { useEffect } from "react";
import { GithubIntegration } from "./features/github";
import { Home } from "./features/home";
import { GithubInstall } from "./features/github/install";
import { JiraIntegration } from "./features/jira";
import { JiraProject } from "./features/jira/jiraProject";

function App() {
  const { user, org, login } = useSession();

  useEffect(() => {
    if (login) {
      redirectLogin();
      return;
    }
  }, [login]);


  if (!user || !org) return <div>Loading</div>;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#650df2",
        },
      }}
    >
      <Routes>
        <Route path="/" element={<Dashboard user={user} org={org} />}>
          <Route path="/" element={<Home />} />
          <Route
            path="/github-integration"
            element={<GithubIntegration org={org} />}
          />
          <Route
            path="/github-integration/install/:installationID"
            element={<GithubInstall />}
          />

          <Route
            path="/jira-integration"
            element={<JiraIntegration org={org} />}
          />

          <Route
            path="/jira-integration/:jiraID"
            element={<JiraProject org={org} />}
          />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}

export default App;
