import { Button, List, Typography } from "antd";
import { getJiraApps, getJiraAuthUrl } from "../../api/jira";
import { useEffect, useState } from "react";
import { ReactComponent as JiraIc } from "../../ui-kit/icons/jira.svg";
import Icon from "@ant-design/icons";
import "./jira.css";

const { Title } = Typography;

export function JiraIntegration({ org }) {
  const [isLoadingAuthUrl, setIsLoadingAuthUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apps, setApps] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(2);
  const [page, setPage] = useState(1);

  const onGetAuthUrl = async () => {
    setIsLoadingAuthUrl(true);
    const { url } = await getJiraAuthUrl();
    window.location.href = url;
  };

  const fetchApps = async () => {
    try {
      setIsLoading(true);
      const { totalCount, apps } = await getJiraApps({ perPage, page });
      setApps(apps);
      setTotalCount(totalCount);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchApps();
  }, [page]);

  const paginationProps = {
    current: page,
    total: totalCount,
    pageSize: perPage,
    onChange: setPage,
  };

  return (
    <div className="Jira">
      <Button
        onClick={onGetAuthUrl}
        disabled={isLoadingAuthUrl}
        loading={isLoadingAuthUrl}
        icon={<Icon component={JiraIc} className="add-btn-ic" />}
      >
        Add Jira Account
      </Button>

      <Title level={5}>List of linked accounts</Title>

      <List
        itemLayout="horizontal"
        dataSource={apps}
        pagination={totalCount ? paginationProps : null}
        loading={isLoading}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button type="link" href={`/jira-integration/${item.jiraID}`}>
                Manage Projects
              </Button>,
              <Button type="link" href={item.url}>
                Go to jira
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={<a href={item.url}>{item.name}</a>}
              // description={item.repository.description}
            />
          </List.Item>
        )}
      />
    </div>
  );
}
