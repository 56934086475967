import { useEffect, useState } from "react";

import { getSessionDetails, getLoginRedirectUrl } from "./authApi";

export function useSession() {
  const [user, setUser] = useState(null);
  const [org, setOrg] = useState(null);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    async function fetchSession() {
      const { userDetails, org } = await getSessionDetails();
      setUser(userDetails);
      setOrg(org);
    }

    fetchSession().catch(() => {
      setUser({});
      setLogin(true);
    });
  }, []);

  return { user, org, login };
}

export async function redirectLogin() {
  const { url } = await getLoginRedirectUrl();
  window.location.href = url;
}
